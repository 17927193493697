<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <v-form>
        <v-row>
          <v-col
            cols="12"
            lg="8"
            md="12"
            sm="12"
          >
            <base-material-card
              color="primary"
              icon="mdi-recycle"
              class="px-4 py-2"
            >
              <template v-slot:after-heading>
                <div
                  class="display-2 font-weight-bold"
                >
                  {{ $t('movementTransactionDetails') }}
                </div>
              </template>
              <v-card-text>
                <v-row
                  dense
                >
                  <v-col
                    cols="4"
                    lg="2"
                    md="4"
                    sm="4"
                  >
                    <v-text-field
                      v-if="movement.TransactionId != 0"
                      v-model="movement.TransactionId"
                      :label="$t('movementLabelTransactionId')"
                      readonly
                      dense
                    />
                    <v-text-field
                      v-else
                      :label="$t('movementLabelTransactionId')"
                      readonly
                      value="New"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="4"
                    lg="2"
                    md="4"
                    sm="4"
                  >
                    <v-text-field
                      :value="movement.TransactionDate | moment('LL')"
                      :label="$t('movementLabelShipDate')"
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    lg="2"
                    md="4"
                    sm="6"
                  >
                    <v-text-field
                      v-model="movement.CompletedDate"
                      type="Date"
                      :error-messages="dateErrors"
                      :label="$t('movementLabelReceiveDate')"
                      dense
                      @input="$v.movement.CompletedDate.$touch()"
                      @blur="$v.movement.CompletedDate.$touch()"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                  >
                    <v-text-field
                      :value="movement.OriginPartnerName"
                      :label="$t('movementLabelShippedFrom')"
                      dense
                      readonly
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                  >
                    <v-text-field
                      :value="movement.OriginProductName"
                      :label="$t('movementLabelShippedProduct')"
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    md="12"
                    sm="12"
                  >
                    <v-select
                      v-if="showTargetListingProducts"
                      v-model="movement.TargetListingId"
                      :items="products"
                      item-text="productAlias"
                      item-value="listingId"
                      :error-messages="targetProductErrors"

                      :label="this.$t('movementLabelOriginProduct')"
                      dense
                      @input="$v.movement.TargetListingId.$touch()"
                      @blur="$v.movement.TargetListingId.$touch()"
                      @change="onChangeReload"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    lg="3"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="movement.OriginQuantity"
                      type="Number"
                      :label="$t('movementLabelShippedQty')"
                      dense
                      readonly
                    />
                  </v-col>
                  <v-col
                    v-if="showTargetQuantity"
                    cols="6"
                    lg="3"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="movement.TargetQuantity"
                      type="Number"
                      :error-messages="targetQuantityErrors"
                      :label="$t('movementLabelReceivedQty')"
                      dense
                      @input="$v.movement.TargetQuantity.$touch()"
                      @blur="$v.movement.TargetQuantity.$touch()"
                      @change="onChangeReload"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    lg="3"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="movement.UnitofMeasureText"
                      :label="$t('movementLabelOriginUnit')"
                      dense
                      readonly
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </base-material-card>
            <base-material-card
              color="info"
              icon="mdi-file-document-outline"
              class="px-4 py-2"
            >
              <template v-slot:after-heading>
                <div
                  class="display-2 font-weight-bold"
                >
                  {{ $t('movementTransactionDocuments') }}
                </div>
              </template>
              <v-card-text>
                <v-data-table
                  :headers="referenceHeaders"
                  :items="movement.documents"
                  item-key="ReferenceType"
                  hide-default-footer
                  :items-per-page="movement.documents.length"
                  single-select
                  class="elevation-1"
                >
                  <template v-slot:item.ReferenceNumber="{ item }">
                    <v-text-field
                      v-model="item.ReferenceNumber"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      dense
                    />
                  </template>
                </v-data-table>
              </v-card-text>
            </base-material-card>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="12"
            sm="12"
          >
            <base-material-card
              color="purple lighten-1"
              class="px-4 py-2"
            >
              <template v-slot:heading>
                <div
                  class="display-2 font-weight-bold"
                >
                  {{ $t('movementTransactionValueChain') }}
                </div>
              </template>
              <v-timeline>
                <v-timeline-item
                  v-for="(item) in movement.valueChain"
                  :key="item.partnerId"
                  color="purple lighten-3"
                  large
                  fill-dot
                >
                  <template v-slot:opposite>
                    <span
                      class="display-1 font-weight-bold"
                    >
                      {{ item.partnerName }}
                    </span>
                  </template>
                </v-timeline-item>
              </v-timeline>
            </base-material-card>
            <buy-back
              v-if="showBuyback"
              :key="reloadBuyback"
              :data-type="buyBackDataType"
              :listing-id="computedListingId"
              :partner-id="computedPartnerId"
              :quantity="computedQuantity"
            />
          </v-col>
        </v-row>
        <v-row
          class="white"
        >
          <v-col
            cols="12"
          >
            <v-btn
              color="success"
              :disabled="disableSubmit"
              @click="onReceiveCreate"
            >
              {{ buttonReceiveText }}
            </v-btn>
            <v-btn
              text
              to="/dashboard"
            >
              {{ $t('buttonClose') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import { required, helpers } from 'vuelidate/lib/validators'
  import productAPI from '@/services/productServices'
  import transactionAPI from '@/services/transaction'
  import i18n from '@/i18n'
  import Vue from 'vue'
  const notZero = (value) => !helpers.req(value) || value > 0
  function initialState () {
    return {
      appTitle: '',
      breadcrumbs: [
        {
          text: i18n.t('bcHome'),
          disabled: false,
          href: '/dashboard',
        },
        {
          text: i18n.t('bcMaterial'),
          disabled: true,
          href: '/material',
        },
        {
          text: i18n.t('bcReceive'),
          disabled: true,
          href: '/material/receivewaste',
        },
      ],
      showErrorDialog: false,
      errorMessage: '',
      modelProcessing: false,
      buyBackDataType: 2,
      movement: {
        TransactionId: 0,
        RelatedTransactionId: 0,
        TransactionType: 0,
        TransactionDate: Vue.moment(new Date(), 'yyyy-MM-dd').format().substring(0, 10),
        CompletedDate: Vue.moment(new Date(), 'yyyy-MM-dd').format().substring(0, 10),
        OriginPartnerId: 0,
        OriginPartnerName: '',
        TargetPartnerId: 0,
        CommitmentPartnerId: 0,
        OriginListingId: 0,
        OriginProductId: 0,
        OriginProductName: '',
        TargetProductId: 0,
        TargetListingId: 0,
        OriginQuantity: 0,
        TargetQuantity: 0,
        UnitofMeasure: 0,
        UnitofMeasureText: '',
        StatusCode: 11,
        documents: [],
        valueChain: [],
      },
      targetPartners: [],
      products: [],
      units: [],
      productType: 11,
      labelPartner: i18n.t('movementLabelTargetPartner'),
      referenceHeaders: [
        {
          text: i18n.t('colReferenceType'),
          align: 'start',
          value: 'ReferenceText',
          sortable: false,
          class: 'info lighten-2 display-1 font-weight-bold',
        },
        {
          text: i18n.t('colReferenceNumber'),
          align: 'start',
          value: 'ReferenceNumber',
          sortable: false,
          width: '40%',
          class: 'info lighten-2 display-1 font-weight-bold',
        },
      ],
      reloadBuyback: 0,
      showBuyback: false,
      showTargetListingProducts: true,
      showTargetQuantity: true,
      buttonReceiveText: i18n.t('buttonReceive'),
    }
  }
  export default {
    Name: 'Movement',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
      BuyBack: () => import('./component/BuyBack'),
    },
    props: {
      transactionId: {
        type: String,
        default: '0',
      },
    },
    data: function () {
      return initialState()
    },
    validations: {
      movement: {
        CompletedDate: {
          required,
        },
        TargetPartnerId: {
          required,
          notZero,
        },
        TargetListingId: {
          required,
          notZero,
        },
        TargetQuantity: {
          required,
          notZero,
        },
        UnitofMeasure: {
          required,
          notZero,
        },
      },
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        documentTypes: 'documentTypes',
      }),
      disableSubmit: function () {
        return this.$v.$invalid
      },
      computedListingId: function () {
        return Number(this.movement.OriginListingId)
      },
      computedPartnerId: function () {
        let valuePartnerId = 0
        if (this.movement.valueChain.length > 0) {
          valuePartnerId = this.movement.valueChain[0].partnerId
        }
        return Number(valuePartnerId)
      },
      computedQuantity: function () {
        return Number(this.movement.TargetQuantity)
      },
      dateErrors () {
        const errors = []
        if (!this.$v.movement.CompletedDate.$dirty) return errors
        !this.$v.movement.CompletedDate.required && errors.push(this.$t('movementReceiveDateRequired'))
        return errors
      },
      targetProductErrors () {
        const errors = []
        if (!this.$v.movement.TargetListingId.$dirty) return errors
        !this.$v.movement.TargetListingId.required && errors.push(this.$t('movementTargetProductRequired'))
        !this.$v.movement.TargetListingId.notZero && errors.push(this.$t('movementTargetProductRequired'))
        return errors
      },
      targetQuantityErrors () {
        const errors = []
        if (!this.$v.movement.TargetQuantity.$dirty) return errors
        !this.$v.movement.TargetQuantity.required && errors.push(this.$t('movementTargetQuantityRequired'))
        !this.$v.movement.TargetQuantity.notZero && errors.push(this.$t('movementTargetQuantityRequired'))
        return errors
      },
    },
    mounted: function () {
      this.modelProcessing = true
      transactionAPI.getMaterialMovement(this.transactionId)
        .then(response => {
          this.initializeForm(response.data)
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
    },
    methods: {
      initializeForm: function (transactionData) {
        this.showTargetListingProducts = true
        this.showBuyback = false
        this.showTargetQuantity = true
        this.productType = 11
        this.buttonReceiveText = this.$t('buttonReceive')
        switch (Number(transactionData.transactionType)) {
          case 1:
            this.movement.TransactionType = 2
            this.appTitle = this.$t('titleTransactionProductReceived') + this.currentUser.partnerName
            break
          case 12:
            this.movement.TransactionType = 11
            this.appTitle = this.$t('titleTransactionWasteReceived') + this.currentUser.partnerName
            if (transactionData.targetPartnerType === 16) {
              this.productType = 21
            } else {
              this.showBuyback = true
            }
            break
          case 14:
            this.movement.TransactionType = 13
            this.appTitle = this.$t('titleTransactionWasteReceived') + this.currentUser.partnerName
            break
          case 31:
            this.movement.TransactionType = 32
            this.appTitle = this.$t('titleTransactionAcceptCommitment') + transactionData.originPartnerName
            this.showTargetListingProducts = false
            this.showTargetQuantity = false
            this.movement.TargetListingId = transactionData.originProductId
            this.movement.TargetProductId = transactionData.originProductId
            this.buttonReceiveText = this.$t('buttonAccept')
            break
        }
        this.movement.RelatedTransactionId = transactionData.transactionId
        this.movement.OriginPartnerId = transactionData.originPartnerId
        this.movement.OriginPartnerName = transactionData.originPartnerName
        this.movement.OriginPartnerType = transactionData.originPartnerType
        this.movement.TargetPartnerId = transactionData.targetPartnerId
        this.movement.TargetPartnerName = transactionData.targetPartnerName
        this.movement.TargetPartnerType = transactionData.targetPartnerType
        this.movement.CommitmentPartnerId = transactionData.commitmentPartnerId
        this.movement.TransactionDate = transactionData.transactionDate
        this.movement.OriginListingId = transactionData.originListingId
        this.movement.OriginProductId = transactionData.originProductId
        this.movement.OriginProductName = transactionData.originProductName
        this.movement.OriginQuantity = transactionData.originQuantity
        this.movement.TargetQuantity = transactionData.originQuantity
        this.movement.UnitofMeasure = transactionData.unitofMeasure
        this.movement.UnitofMeasureText = transactionData.unitofMeasureText
        this.movement.valueChain = transactionData.sourceValueChain
        if (this.showTargetListingProducts) {
          productAPI.getProductListingsByProductType(this.productType)
            .then(response => {
              this.products = response.data
            })
        }
        if (this.documentTypes === null) {
          this.$store.dispatch('GET_DOCUMENT_TYPES')
            .then(response => {
              this.updateDocuments()
            })
        } else if (this.documentTypes.length === 0) {
          this.$store.dispatch('GET_DOCUMENT_TYPES')
            .then(response => {
              this.updateDocuments()
            })
        } else {
          this.updateDocuments()
        }
        this.reloadBuyback++
      },
      updateDocuments: function () {
        this.movement.documents = []
        let i = 1
        for (const item of this.documentTypes) {
          if (Number(item.key) === 3 || Number(item.key) === 5 || Number(item.key) === 6) {
            const doc = {
              TransactionId: 0,
              LineNumber: i,
              ReferenceType: Number(item.key),
              ReferenceText: item.value,
              ReferenceNumber: '',
            }
            this.movement.documents.push(doc)
            i++
          }
        }
      },
      onChangeReload: function () {
        this.reloadBuyback++
      },
      onReceiveCreate: function () {
        this.modelProcessing = true
        transactionAPI.receiveMaterial(this.movement)
          .then(response => {
            this.modelProcessing = false
            // verify if error in response
            if (response.data != null) {
              switch (response.data.type) {
                case 1:
                  // error
                  this.errorMessage = response.data.errorMessage
                  this.showErrorDialog = true
                  break
                default:
                  Object.assign(this.$data, initialState())
                  this.$router.push('/dashboard')
                  break
              }
            }
          })
          .catch(error => {
            this.showErrorDialog = true
            this.errorMessage = error.message
            this.modelProcessing = false
          })
      },
    },
  }
</script>
